import { Resource } from 'react-admin';

import UserIcon from '@material-ui/icons/Group';
import RoleIcon from '@material-ui/icons/HowToReg';

import { JournalistCreate, JournalistEdit, JournalistList } from '../../views/journalists';
import { SubscriberCreate, SubscriberEdit, SubscriberList } from '../../views/subscribers';
import { RoleList, RoleEdit, RoleCreate } from '../../views/roles';
import { ClubCardList, ClubCardCreate, ClubCardEdit, ClubCardShow } from '../../views/club_cards';
import { BenefitsList } from '../../views/benefits';
import { ContactFormCreate } from "../../views/contact_form_create";
import { NonPermission } from '../../NonPermission';
import ViewSubscriptions from "../../views/metrics/view-subscriptions";
import ViewVisitedUrl from "../../views/visited_url/view-visitedurl";
import { messages } from '../../language/language-es';
import { checkPermissions } from '../../utils/validations';



const Resources = (permissions) => [
    <Resource name="journalists"
        list={checkPermissions(permissions, 'list-journalists') ? JournalistList : null}
        edit={checkPermissions(permissions, 'edit-journalists') ? JournalistEdit : NonPermission}
        create={checkPermissions(permissions, 'create-journalists') ? JournalistCreate : NonPermission}
        icon={UserIcon}
        options={{ label: messages.resources.journalists.name }}
    />,
    <Resource name="subscribers"
        list={checkPermissions(permissions, 'list-subscribers') ? SubscriberList : null}
        edit={checkPermissions(permissions, 'edit-subscribers') ? SubscriberEdit : NonPermission}
        create={checkPermissions(permissions, 'create-subscribers') ? SubscriberCreate : NonPermission}
        icon={UserIcon}
        options={{ label: messages.resources.subscribers.name }}
    />,
    <Resource name="roles"
        list={checkPermissions(permissions, 'list-roles') ? RoleList : null}
        edit={checkPermissions(permissions, 'edit-roles') ? RoleEdit : NonPermission}
        create={checkPermissions(permissions, 'create-roles') ? RoleCreate : NonPermission}
        icon={RoleIcon}
        options={{ label: messages.resources.roles.name }}
    />,
    <Resource
        name="submenu_metrics"
        options={{
            label: messages.resources.metrics.name,
            labelUsers: messages.resources.metrics.titles.title_users,
            labelSubscriptions: messages.resources.metrics.titles.title_subscriptions,
            labelHistory: messages.resources.metrics.titles.title_history,
        }}
    />,
    <Resource
        name="metrics-subscriptions"
        list={
            checkPermissions(permissions, "list-metrics")
                ? ViewSubscriptions
                : NonPermission
        }
    />,
    <Resource
        name="visited-url"
        list={
            checkPermissions(permissions, "list-metrics")
                ? ViewVisitedUrl
                : NonPermission
        }
    />,

    <Resource name="submenu_club"
        options={{ label: messages.resources.club.name, labelClub: messages.resources.club_cards.name, labelBenefit: messages.resources.benefits.name }}
    />,
    <Resource name="subscriptions" />,
    <Resource name="benefits"
        list={checkPermissions(permissions, 'list-benefits') ? BenefitsList : NonPermission}
    />,
    <Resource name='club_cards'
        list={checkPermissions(permissions, 'list-club-cards') ? ClubCardList : NonPermission}
        create={checkPermissions(permissions, 'create-club-cards') ? ClubCardCreate : NonPermission}
        edit={checkPermissions(permissions, 'edit-club-cards') ? ClubCardEdit : NonPermission}
        show={checkPermissions(permissions, 'list-club-cards') ? ClubCardShow : NonPermission}
    />,
    <Resource name="users" />,
    <Resource name="contact_form" create={ContactFormCreate} />,
    <Resource name="plans" />,
]

export default Resources;